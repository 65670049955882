import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { RiMenu5Fill } from "react-icons/ri";
import { motion } from "framer-motion";
import { client } from "../lib/clients";

const Header = () => {
  const [menuClk, setMenuClk] = useState(false);
  const [submenuClk, setSubMenuClk] = useState(false);

  const menuClick = () => {
    setMenuClk(!menuClk);
  };
  const submenuClick = () => {
    setSubMenuClk(!submenuClk);
  };
  const closeMN = () => {
    setMenuClk(false);
    setSubMenuClk(false);
  };

  const [saydata, setSaydata] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `*[_type == "gallery"]{
        title,
        slug,
        publishedAt,       
    } | order(publishedAt, asc)`
      )
      .then((data) => {
        setSaydata(data);
      })
      .catch(console.error);
  }, []);

  const socialLink = (link) => {
    switch (link) {
      case "facebook":
        window.open("https://www.facebook.com/photoinfinity.ch", "_blank");
        break;
      case "insta":
        window.open(
          "https://instagram.com/photoinfinity.ch?igshid=19dn6c2ctunkh",
          "_blank"
        );
        break;

      default:
        break;
    }
  };
  return (
    <>
      {menuClk && (
        <>
          <div className="">
            <motion.aside
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -200 }}
              id="apo-hidden-column"
              className="apo-hidden-column flex w-full md:w-[395px]"
            >
              <div className="apo-hidden-column-container">
                <motion.header
                  initial={{ opacity: 0, x: 200 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 200 }}
                  className="apo-hidden-column-header"
                >
                  <div className="apo-hidden-column-col">
                    <div className="apo-grid apo-cols-2 flex flex-row justify-center items-center ">
                      <div className="apo-grid-col">
                        <Link onClick={closeMN} to="/" className="apo-logo">
                          <img
                            className=""
                            src="/images/logo-pi-2.png"
                            alt=""
                          />
                        </Link>
                      </div>
                      <div className="apo-grid-col apo-align-right">
                        {/* Social Networks*/}
                        <ul className="apo-social-networks">
                          <li>
                            <Link
                              onClick={() => socialLink("facebook")}
                              to="#"
                              target="_blank"
                            >
                              <FaFacebookF />
                            </Link>
                          </li>

                          <li>
                            <Link
                              onClick={() => socialLink("insta")}
                              to="#"
                              target="_blank"
                            >
                              <BsInstagram />
                            </Link>
                          </li>
                        </ul>
                        {/* End Social Networks*/}
                      </div>
                      <div
                        className="AiOutlineClose md:hidden"
                        onClick={menuClick}
                      >
                        <AiOutlineClose className=" text-textColor text-[25px]" />
                      </div>
                    </div>
                  </div>
                </motion.header>
                <div className="apo-hidden-column-content">
                  <div className="apo-hidden-column-col">
                    {/* Navigation*/}
                    <nav className="apo-navigation-container nav-scrollbar">
                      <ul className="apo-navigation">
                        <li className="apo-has-children apo-current">
                          <Link onClick={closeMN} to="/">
                            Home
                          </Link>
                        </li>
                        <li className="apo-has-children">
                          <Link to="#" onClick={submenuClick}>
                            Gallery
                          </Link>
                          {submenuClk && (
                            <motion.ul
                              initial={{ opacity: 0, y: 100 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: 100 }}
                              className="apo-sub-menu"
                            >
                              {saydata &&
                                saydata.map((item, index) => (
                                  <li key={index}>
                                    {item.slug.current === "kids-world" ? (
                                      <a
                                        className=" uppercase"
                                        onClick={closeMN}
                                        href={`/gallery/${item.slug.current}`}
                                      >
                                        {item.title}
                                      </a>
                                    ) : (
                                      <Link
                                        className=" uppercase"
                                        onClick={closeMN}
                                        to={`/gallery/${item.slug.current}`}
                                      >
                                        {item.title}
                                      </Link>
                                    )}
                                  </li>
                                ))}

                              {/* <li>
                                <Link onClick={closeMN} to="customer-gallery">
                                  CUSTOMER GALLERY
                                </Link>
                              </li> */}
                            </motion.ul>
                          )}
                        </li>
                        <li className="apo-has-children hidden">
                          <Link onClick={closeMN} to="blog">
                            Blog
                          </Link>
                        </li>
                        <li>
                          <Link onClick={closeMN} to="contact">
                            Contact
                          </Link>
                        </li>
                        <li className="apo-has-children">
                          <Link onClick={closeMN} to="about">
                            About
                          </Link>
                        </li>
                        <li className="apo-has-children">
                          <Link onClick={closeMN} to="testimonial">
                            Testimonial
                          </Link>
                        </li>
                      </ul>
                    </nav>
                    {/* End Navigation*/}
                  </div>
                </div>
                <footer className="apo-hidden-column-footer">
                  <div className="apo-hidden-column-col">
                    <div className="apo-hidden-column-actions">
                      <div className="apo-hidden-column-action">
                        <p className="flex text-textColor text-xl ">
                          Copyright @ 2023, Photoinfinity
                        </p>
                      </div>
                      <div className="apo-hidden-column-action">
                        <span
                          className="flex justify-start gap-5 items-center font-light text-textColor cursor-pointer"
                          onClick={closeMN}
                        >
                          <AiOutlineClose /> CLOSE
                        </span>
                      </div>
                    </div>
                  </div>
                </footer>
              </div>
            </motion.aside>
          </div>
        </>
      )}

      <header
        id="header"
        className="apo-header apo-header-light apo-header-vertical flex w-full md:w-[110px]"
      >
        <div className="apo-header-section md:flex md:justify-between md:items-center md:flex-col flex-row">
          <div className="apo-header-component-first">
            <div className="apo-header-items inline-block max-sm:flex max-sm:justify-between mx-3 md:mx-0 max-sm:items-center max-sm:flex-row">
              <motion.div
                whileTap={{ scale: 0.5 }}
                className=" inline-block  pt-[15px] md:hidden"
                onClick={menuClick}
              >
                <RiMenu5Fill className=" cursor-pointer text-[40px] text-gray-500 font-light" />
              </motion.div>

              <div className="apo-header-item max-ssm:w-[50%]">
                {/* Logo*/}
                <Link
                  to="/"
                  title="Home"
                  className="apo-logo flex justify-center items-center"
                >
                  <img
                    className=" w-[50%] max-ssm:hidden md:flex md:w-full"
                    src="/images/pi-logo.png"
                    alt="Logo"
                  />
                  <img
                    className=" w-[50%] md:hidden flex md:w-full"
                    src="/images/logo-pi-2.png"
                    alt="Logo"
                  />
                </Link>
                {/* End Logo*/}
              </div>
              <div className="apo-header-item">
                {/* Social Networks*/}
                <ul className="apo-social-networks">
                  <li>
                    <Link to="#" onClick={() => socialLink("facebook")}>
                      <FaFacebookF className="m-auto" />
                    </Link>
                  </li>

                  <li>
                    <Link to="#" onClick={() => socialLink("insta")}>
                      <BsInstagram className="m-auto" />
                    </Link>
                  </li>
                </ul>
                {/* End Social Networks*/}
              </div>
            </div>
          </div>
          <div className="apo-header-component-middle md:flex ">
            <div className="apo-header-items max-ssm:h-0 ">
              <div className="apo-header-item md:h-[260px]">
                {/* <h1 className="apo-page-title md:hidden flex">PhotoInfinity</h1> */}
                <img
                  className=" w-[50%] justify-center items-center m-auto md:flex max-ssm:hidden"
                  src="/images/logo-pi.png"
                  alt="logo"
                />
              </div>
            </div>
          </div>
          <div className="apo-header-component-last max-ssm:opacity-0 max-sm:h-0">
            <div className="apo-header-items max-ssm:h-0">
              <div className="apo-header-item" onClick={menuClick}>
                {/* Navigation Button*/}
                <div type="button" className="flex justify-center items-center">
                  <motion.div whileTap={{ scale: 0.5 }}>
                    <RiMenu5Fill className=" cursor-pointer text-[40px] text-gray-500 font-light" />
                  </motion.div>
                </div>
                {/* End Navigation Button*/}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
