import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import { motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";
import { client } from "../lib/clients";
import imageUrlBuilder from "@sanity/image-url";
import StackGrid, { transitions } from "react-stack-grid";
import sizeMe from "react-sizeme";

const Gallery = ({ size }) => {
  const [viewimg, setViewimg] = useState(false);
  const [addimg, setAddimg] = useState("");

  const imgFullsize = (img) => {
    setAddimg(img);
    setViewimg(!viewimg);
  };

  const builder = imageUrlBuilder(client);
  function urlFor(source) {
    return builder.image(source);
  }
  const [gallerydata, setGallerydata] = useState(null);
  const { slug } = useParams();
  useEffect(() => {
    client
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          imagesGallery[]{
            asset->{
              _id,
              url
             }
           },       
       }`,
        { slug }
      )
      .then((data) => setGallerydata(data))
      .catch(console.error);
  }, [slug]);

  if (!gallerydata) {
    return (
      <div className=" w-full h-screen flex justify-center items-center">
        <h1>Loading......</h1>
      </div>
    );
  }
  return (
    <>
      {viewimg && (
        <motion.div
          initial={{ opacity: 0, y: -300 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -300 }}
          className=" w-full h-screen fixed top-0 left-0 bg-slate-100 z-[10000] flex justify-center items-center"
        >
          <div className=" w-[90%] md:w-[100%] h-[500px]  m-auto">
            <AiOutlineClose
              onClick={() => setViewimg(!viewimg)}
              className=" absolute right-12 top-10 md:right-40 md:top-20 text-[3rem] cursor-pointer"
            />
            <img
              className=" max-h-[500px] md:h-[inherit] m-auto shadow-2xl"
              src={addimg}
              alt="img1"
            />
          </div>
        </motion.div>
      )}

      {gallerydata &&
        gallerydata.map((gallery, index) => (
          <div className="apo-page mb-6" key={index}>
            <div className="apo-page-header apo-style-3 py-12 pb-4 flex md:flex-row flex-col  justify-between items-center">
              <div className="apo-page-header-column ">
                <h1 className="apo-page-title text-left text-[2.3rem] before:border-black uppercase">
                  {gallery.title}
                </h1>
              </div>
              <div className="flex justify-center items-center gap-7 mr-10">
                {gallery.title === "Weddings" ? (
                  <></>
                ) : (
                  <Link to="/gallery/weddings">
                    {" "}
                    <h1 className=" text-left md:text-[2.3rem] text-[1rem] before:border-black uppercase m-0 cursor-pointer bg-slate-300 rounded-lg px-7 py-2">
                      WEDDING
                    </h1>{" "}
                  </Link>
                )}
                {gallery.title === "Kids World" ? (
                  <></>
                ) : (
                  <a href="/gallery/kids-world">
                    <h1 className=" text-left md:text-[2.3rem] text-[1rem] before:border-black uppercase m-0 cursor-pointer bg-slate-300 rounded-lg px-7 py-2">
                      KIDS WORLD
                    </h1>
                  </a>
                )}
                {gallery.title === "Portrait" ? (
                  <></>
                ) : (
                  <Link to="/gallery/portrait">
                    <h1 className=" text-left md:text-[2.3rem] text-[1rem] before:border-black uppercase m-0 cursor-pointer bg-slate-300 rounded-lg px-7 py-2">
                      PORTRAIT
                    </h1>
                  </Link>
                )}
              </div>
            </div>
            <div className="apo-parallax-grid apo-portfolio-container apo-style-2 apo-portfolio-appear ">
              <StackGrid
                columnWidth={size.width <= 768 ? "100%" : "33.33%"}
                appearDelay={0}
                monitorImagesLoaded={true}
                vendorPrefix={true}
                gutterHeight={7}
                itemComponent="article"
                component="section"
              >
                {gallery.imagesGallery.map((val, ind) => (
                  <>
                    <div
                      key={val.asset._id + ind}
                      className="h-[100%]  w-[100%] p-10 bg-slate-50"
                    >
                      <article className="apo-project">
                        <div className="apo-project-media">
                          <Link to="#">
                            <img src={urlFor(val.asset.url)} alt="" />
                          </Link>
                        </div>
                        <div className="apo-project-content-wrap">
                          <div className="apo-aligner-outer">
                            <div className="apo-aligner-inner">
                              <header className="apo-project-header">
                                <h2 className="apo-project-title text-[1.4rem]">
                                  <Link
                                    to="#"
                                    onClick={() => imgFullsize(val.asset.url)}
                                  >
                                    PhotoInfinity
                                  </Link>
                                </h2>
                                <ul className="apo-project-categories flex justify-center items-center">
                                  <motion.li
                                    whileHover={{ scale: 1.3 }}
                                    className=" transition-all ease-in-out"
                                    onClick={() => imgFullsize(val.asset.url)}
                                  >
                                    <FiEye className=" text-[2rem]" />
                                  </motion.li>
                                </ul>
                              </header>
                            </div>
                          </div>
                        </div>
                      </article>
                    </div>
                  </>
                ))}
              </StackGrid>
            </div>
          </div>
        ))}
    </>
  );
};

export default sizeMe()(Gallery);
